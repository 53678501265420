<template>
    <div class="HomeTitle">
        <span class="titles_left">{{title}}</span>
        <span class="titles_right"
              @click="callMore">更多 ></span>
    </div>
</template>

<script>


export default {
    name: 'HomeTitle',
    components: {},
    props: {
        title: {
            type: String
        }
    },
    data () {
        return {};
    },
    methods: {
        callMore () {
            this.$emit('callMore');
        }
    },
    created () {
    },
    mounted () {
    },
    computed: {},
    watch: {}
}
</script>

<style scoped lang="less">
    .HomeTitle {
        padding-top: 32px;
        display: flex;
        justify-content: space-between;
        .titles_left {
            font-size: 32px;
            font-weight: 700;
            color: #353535;
            line-height: 44px;
        }
        .titles_right {
            font-size: 24px;
            font-weight: 400;
            color: #A4A4A4;
            line-height: 44px;
        }
    }
</style>
