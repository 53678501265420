<template>
    <div class="Matrix">
        <template v-if="list.length">
            <div class="Matrix_item"
                 @click="callMore(item)"
                 v-for="(item, index) in list" :key="index">
                <div class="imgs">
                    <!--<img style="height: 30px;width: 100%" :src="item[propType.img]" alt="">-->
                    <baseImage :url="item[propType.img]"/>
                </div>
                <div class="copywriting">
                    <h6>{{item[propType.name]}}</h6>
                    <div class="copy_dec">
                        <span>{{item[propType.date]}}</span>
                        <span class="of_eno">{{item[propType.user]}}</span>
                    </div>
                </div>
            </div>
        </template>
        <baseNoData v-else/>
    </div>
</template>

<script>

export default {
    name: 'Matrix',
    components: {},
    props: {
        list: {
            type: Array,
            default () {
                return []
            }
        },
        propType: {
            type: Object,
            default(){
                return {
                    img: 'cover',
                    name: 'name',
                    date: 'startTime',
                    user: 'teacherName'
                }
            }
        }
    },
    data () {
        return {};
    },
    methods: {
        callMore (item) {
            this.$emit('callMore', item);
        }
    },
    created () {
    },
    mounted () {
    },
    computed: {},
    watch: {}
}
</script>

<style scoped lang="less">
    .Matrix {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .Matrix_item {
            margin-top: 24px;
            width: 324px;
            /*height: 312px;*/
            background: #FFFFFF;
            border-radius: 8px;
        }
        .imgs {
            position: relative;
            width: 100%;
            height: 0;
            padding: 34% 0;
            .van-image {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .copywriting {
            padding: 20px;
            h6 {
                margin: 0;
                padding: 0;
                font-size: 24px;
                font-weight: 500;
                color: #353535;
                line-height: 28px;
                height: 28px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .copy_dec {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                span {
                    display: inline-block;
                    font-size: 20px;
                    font-weight: 400;
                    color: #969696;
                    line-height: 30px;
                    height: 30px;
                    max-width: 50%;
                }
            }
        }
    }
</style>
