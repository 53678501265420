<template>
    <div class="head_box">
        <div class="imgs">
            <img src="../assets/images/logo@2x.png" />
            <p>{{userVo.corpName || '云教室'}}</p>
        </div>
        <div class="head_right">
            <router-link to="/login" v-if="!culength">登录</router-link>
            <a v-else @click="$store.dispatch('users/logout')">退出</a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "heads",
    computed: {
        ...mapState({
            userVo: state => state.users.userVo
        }),
        culength () {
            return Object.keys(this.userVo).length;
        }
    },
    data () {
        return {};
    },
    methods: {
    },
    created () {
        console.log(this.userVo, 11)
    },
    mounted () {
    },
    components: {},
    watch: {}
};
</script>

<style scoped lang="less">
.head_box {
    box-sizing: border-box;
    padding: 18px 40px;
    height: 88px;
    width: 100%;
    background: #f7f7f7;
    border-bottom: 1px solid rgba(233, 233, 233, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head_right {
        height: 48px;
        a {
            line-height: 50px;
            font-size: 24px;
            color: #353535;
            display: inline-block;
            &:first-child {
                margin-right: 12px;
            }
        }
    }
    .imgs {
        height: 52px;
        display: flex;
        align-items: center;
        img {
            height: 100%;
            width: auto;
        }
        p {
            font-size: 14px;
            margin-left: 20px;
            color: #1a78f9;
            font-weight: 600;
        }
    }
}
</style>
