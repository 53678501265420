<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <baseContainer>
        <template v-slot:heads>
            <Heads></Heads>
        </template>
        <div class="home">
            <van-swipe :autoplay="3000" class="banners">
                <template v-if="bannerList.length">
                    <van-swipe-item v-for="(item) in bannerList" :key="item.id">
                        <a v-if="item.linkUrlH5" :href="item.linkUrlH5" target="_blank">
                            <img :src="item.imgUrlH5"  style="height: 100%;width: 100%"  />
                        </a>
                        <img v-else :src="item.imgUrlH5" style="height: 100%;width: 100%" />
                    </van-swipe-item>
                </template>
                <van-swipe-item v-else>
                    <img src="../../assets/images/defult.png" style="height: 100%;width: 100%" />
                </van-swipe-item>
            </van-swipe>
            <div class="home_module">
                <HomeTitle title="近期直播" @callMore="$router.push('/liveList')" />
                <Matrix :list="recentList" @callMore="(item)=>{callMore(item, '/liveDetails')}" />
                <HomeTitle title="精彩回放" @callMore="$router.push('/wonReplay')" />
                <Matrix :list="wonReplayList" @callMore="(item)=>{callMore(item, '/liveDetails')}" />
                <HomeTitle title="直播课程" @callMore="$router.push('/liveCourse')" />
                <Matrix :list="courseList" :propType="propType" @callMore="(item)=>{callMore(item, '/courseDetails')}" />
                <HomeTitle title="专题栏目" @callMore="$router.push('/specialColumn')" />
                <SpecialColumn v-if="subjectList.length" :list="subjectList" @callMore="(item)=>{callMore(item, '/columnDetails')}" />
                <baseNoData v-else />
            </div>
        </div>
        <template v-slot:foots>
            <Foots></Foots>
        </template>
    </baseContainer>
</template>

<script>
import Heads from '../../components/heads';
import Foots from '../../components/foots';
import HomeTitle from './components/HomeTitle';
import Matrix from './components/Matrix';
import SpecialColumn from './components/SpecialColumn';
import api from './../../api/home';

export default {
    name: "",
    components: {
        Heads,
        Foots,
        HomeTitle,
        Matrix,
        SpecialColumn
    },
    data () {
        return {
            propType: {
                img: 'courseCover',
                name: 'name',
                date: 'createTime',
                user: 'teacherName'
            },
            bannerList: [],
            recentList: [],
            wonReplayList: [],
            courseList: [],
            subjectList: []
        };
    },
    methods: {
        callMore (item, url) {
            this.$utils.callMores(item, url);
        },
        // banner 列表
        getHomeBannerList () {
            api.getHomeBannerList().then(res => {
                if (res) {
                    this.bannerList = res;
                }
            });
        },
        // 近期直播
        getRecentLiveList () {
            api.getQueryRecentLiveList({
                current: 1,
                size: 4
            }).then(res => {
                if (res) {
                    this.recentList = res.records.map(item =>
                        ({ ...item, startTime: item.start }));
                }
            });
        },
        // 精彩回放
        getWonReplayList () {
            api.getHomeLiveMap().then(res => {
                if (res) {
                    this.wonReplayList = res.CLOSED.map(item =>
                        ({ ...item, startTime: item.startTime.split(' ')[0] }));
                }
            });
        },
        // 直播课程
        getHomeCourseList () {
            api.getHomeCourseList().then(res => {
                this.courseList = res.records.map(item =>
                    ({ ...item, createTime: item.createTime.split(' ')[0] }));
            });
        },
        // 查询首页专题列表
        getQueryHomeSubjectList () {
            api.getQueryHomeSubjectList({
                current: 1,
                size: 4
            }).then(res => {
                if (res) {
                    this.subjectList = res.records;
                }
            });
        }
    },
    created () {
    },
    mounted () {
        this.getHomeBannerList();
        this.getRecentLiveList();
        this.getWonReplayList();
        this.getHomeCourseList();
        this.getQueryHomeSubjectList();
    },
    computed: {},
    watch: {}
};
</script>
<style lang="less" scoped>
.home {
    background: #f7f7f7;
    /*padding-top: 10px;*/
}

.home_module {
    padding: 0 40px 32px;
}

.home_titles {
    padding-top: 32px;
    display: flex;
    justify-content: space-between;
    .titles_left {
        font-size: 32px;
        font-weight: 500;
        color: #353535;
        line-height: 44px;
    }
    .titles_right {
        font-size: 24px;
        font-weight: 400;
        color: #a4a4a4;
        line-height: 44px;
    }
}

.banners {
    margin: 0 0 10px;
    height: 316px;
    .van-swipe {
        height: 100%;
    }
}
</style>

